import { Fragment, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { AppLayout } from 'components/app/app-layout';
import { RepoMoreFilters } from 'components/repo/repo-more-filters';
import { SelectDrop } from 'molecules/drops';
import { SearchForm } from 'molecules/forms';

const RepoDisplayActions = ({
	options = ['search', 'views', 'more', 'layout'],
	views = ['alphabetical', 'trending', 'popular', 'recent', 'recommended', 'viewed'],
	params,
	setParams,
	query,
	setQuery,
}) => {
	const [filters, setFilters] = useState<any>({});
	const router = useRouter();

	// Hooks
	useEffect(() => {
		const formattedParams = { ...params, ...router.query };
		setFilters(formattedParams);
	}, [params, router.query]);

	// Methods
	const setRouterFilter = filter => {
		const query = { ...router.query, ...filter };
		router.push({ query }, undefined, { shallow: true });
	};

	// Render
	return (
		<Fragment>
			{options.includes('search') && (
				<SearchForm
					placeholder='Search repos...'
					query={query}
					setQuery={setQuery}
					tailwind={{ inputGroup: { width: 'w-full' }, input: { spacing: 'px-0 py-2.5' } }}
				/>
			)}
			{options.includes('views') && (
				<SelectDrop
					id='sort_drop'
					prevIcon='bars-sort'
					value={filters.view || 'alphabetical'}
					placeholder='Select view'
					options={views}
					onChange={view => setRouterFilter({ view: view !== 'alphabetical' ? view : '' })}
					tailwind={{ btn: { width: 'w-full' } }}
				/>
			)}
			{options.includes('more') && <RepoMoreFilters params={params} setParams={setParams} tailwind={{}} />}
			{options.includes('layout') && <AppLayout tailwind={{}} />}
		</Fragment>
	);
};

export { RepoDisplayActions };
